import React from 'react';
import Layout from '../components/Layout';
import Call from "../components/Call";
import {graphql} from "gatsby";

const Privacy = props => {

    const {data} = props.data;


    return (
        <Layout>
            <div className="container pb-6 pt-6">
                <div className="row justify-content-start">
                    <div className="col-12 col-md-8">
                        {<div className="justify-content-start pb-2">
                            <div className="pt-6">
                                <div dangerouslySetInnerHTML={{__html: data.html}}/>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export const query = graphql`
  query {
    data: markdownRemark(fileAbsolutePath: {regex: "/(Privacy.md)/"}) {
      html
    }
  }
`;

export default Privacy;
